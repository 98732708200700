/* PhotoGallery.css */
.custom-arrow {
    background-color: rgba(255, 255, 255, 0.8);
    border: none;
    border-radius: 50%;
    cursor: pointer;
    font-size: 24px; /* Adjust size as needed */
    height: 40px; /* Adjust height */
    width: 40px; /* Adjust width */
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 10;
    transition: background-color 0.3s;
  }
  
  .left-arrow {
    left: 10px; /* Adjust position from the left */
  }
  
  .right-arrow {
    right: 10px; /* Adjust position from the right */
  }
  
  .custom-arrow:hover {
    background-color: rgba(255, 255, 255, 1); /* Change background on hover */
  }
  
  .photo-gallery {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 10px;
    padding: 20px;
  }
  
  .photo-gallery img {
    width: 100%;
    border-radius: 8px;
    transition: transform 0.3s ease;
  }
  
  .photo-gallery img:hover {
    transform: scale(1.05);
  }